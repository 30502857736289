<template>
 <div
  v-show="errorLogin"
  class="p-2 mx-2 border border-red-200 rounded-md bg-red-50">
  <div class="flex">
   <div class="flex-shrink-0">
    <ExclamationIcon class="w-5 h-5 text-red-400" aria-hidden="true" />
   </div>
   <div class="ml-3">
    <h3 class="text-sm font-medium text-red-800">{{ $t("errorLogin") }}.</h3>
   </div>
  </div>
 </div>
 <div class="w-full max-w-md space-y-8">
  <form class="mt-3 space-y-3" @submit.prevent="onSubmit" method="POST">
   <div>
    <label
     for="email"
     class="block text-sm font-medium leading-6 text-gray-900">
     {{ $t("login.emailAddress") }}
    </label>
    <div>
     <input
      required
      v-model="form.email"
      id="email"
      name="email"
      type="email"
      autocomplete="email"
      :disabled="isDisabled"
      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
    </div>
   </div>

   <div>
    <label
     for="password"
     class="block text-sm font-medium leading-6 text-gray-900">
     {{ $t("login.password") }}
    </label>
    <div>
     <input
      required
      v-model="form.password"
      id="password"
      name="password"
      type="password"
      autocomplete="current-password"
      :disabled="isDisabled"
      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
    </div>
   </div>

   <div class="flex items-center justify-between py-2" v-if="!isGuestUser">
    <div class="text-sm">
     <a
      @click="emitQueryParam('newPassword', form.email)"
      href="javascript:void(0)"
      class="font-medium encom_ouvert_ticket_text">
      {{ $t("login.newPassword") }}
     </a>
    </div>

    <div class="text-sm forgot_margin">
     <a
      @click="emitQueryParam('resetPassword', form.email)"
      href="javascript:void(0)"
      class="font-medium encom_ouvert_ticket_text">
      {{ $t("login.forgotPassword") }}
     </a>
    </div>
   </div>

   <div>
    <button
     type="submit"
     class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md body__header--button group focus:outline-none encom_ring_color">
     <span class="absolute inset-y-0 left-0 flex items-center pl-3">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 20 20"
       fill="currentColor"
       class="w-5 h-5 encom_primary_text group-hover:encom_secondary_text">
       <path
        fill-rule="evenodd"
        d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
        clip-rule="evenodd" />
      </svg>
     </span>
     {{ $t("login.signin") }}
    </button>
   </div>
  </form>
 </div>
</template>

<script>
import axios from "axios";
import { ExclamationIcon, LockClosedIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

export default {
 props: ["isGuestUser"],
 components: {
  ExclamationIcon,
  LockClosedIcon,
 },
 data() {
  return {
   errorLogin: false,
   isDisabled: false,
   form: {
    email: "",
    password: "",
   },
   hostsNames: [],
   account: "",
  };
 },
 methods: {
  async onSubmit() {
   const userPermissions = { permissions: [] };
   const data = {
    email: this.form.email,
    password: this.form.password,
   };
   // Attempt to log in
   this.$emit("setLoading", true);
   await axios
    .post(`${this.$cookie.getCookie("API")}/api/login`, data)
    .then(res => {
     // Set headers and localStorage items after successful login
     axios.defaults.headers.common["Authorization"] =
      "Bearer " + res.data.token;
     localStorage.setItem("token", res.data.token);
     this.$store.dispatch("token", res.data.token);
     this.$store.commit(
      "setUserPermissions",
      userPermissions ? userPermissions : null
     );
     localStorage.setItem("userPermissions", JSON.stringify(userPermissions));
     localStorage.removeItem("first_login");
     if (
      res.data.user.accounts.includes("ADMIN") ||
      res.data.user.activeAccount.includes("ADMIN")
     ) {
      localStorage.setItem("adminToken", res.data.token);
      this.$store.dispatch("adminToken", res.data.token);
     }
     localStorage.setItem("avatar", res.data.user.avatar);
     if (!res.data.user.activeAccount) {
      localStorage.setItem("account", res.data.user.accounts[0]);
      this.$store.dispatch("account", res.data.user.accounts[0]);
      this.account = res.data.user.accounts[0];
     } else {
      localStorage.setItem("account", res.data.user.activeAccount);
      this.$store.dispatch("account", res.data.user.activeAccount);
      this.account = res.data.user.activeAccount;

      localStorage.setItem(
       "customerAccounts",
       JSON.stringify(res.data.customerAccounts)
      );

      const activeAccount = res.data.customerAccounts.filter(
       account => account.customerAccount === res.data.user.activeAccount
      );
      if (activeAccount.length > 0) {
       localStorage.setItem(
        "activeAccount",
        activeAccount[0].name + " - " + activeAccount[0].customerAccount
       );
      }
     }

     localStorage.setItem("showBanner", true);
     this.$store.dispatch("user", res.data.user);
     localStorage.setItem("user", JSON.stringify(res.data.user));

     const initials = res.data.user.name
      .split(" ")
      .map(name => name.charAt(0))
      .join("");
     localStorage.setItem("initials", initials);
     this.$store.dispatch("userAvatar", res.data.user.avatar);
     if (this.account !== "ADMIN") {
      this.getPbxHosts(
       res.data.token,
       res.data.user.activeAccount
        ? res.data.user.activeAccount
        : res.data.user.accounts[0]
      );
     }
    })
    .then(() => {
     localStorage.getItem("sandbox") === "false"
      ? this.apiLogin()
      : this.apiLoginSandbox();
    })
    .then(() => {
     if (this.account !== "ADMIN") {
      this.getPbxHosts(localStorage.getItem("token"), this.account).then(() => {
       this.getServices(this.account).then(() => {
        this.getModules().then(() => {
         this.getMyPermissions(this.account).then(res => {
          this.$nextTick(() => {
           this.$router.push("/").then(() => location.reload());
          });
         });
        });
       });
      });
      // this.getServices(this.account).then(() => {
      //  this.getModules().then(() => {
      //   this.getMyPermissions(this.account).then(res => {
      //    this.$nextTick(() => {
      //     let permissions = res.data.permissions ? res.data.permissions : [];
      //     if (
      //      permissions.length > 0 &&
      //      permissions.indexOf("expert-statistics.*") >= 0
      //     ) {
      //      this.apiLogin().then(() => location.reload());
      //     }
      //    });
      //   });
      //  });
      // });
     } else {
      this.$router.push("/impersonate").then(() => {
       this.$emit("setLoading", false);
      });
     }
     this.errorLogin = false;
    })
    .catch(err => {
     console.error(err);
     localStorage.removeItem("token");
     localStorage.removeItem("adminToken");
     localStorage.removeItem("avatar");
     localStorage.removeItem("account");
     this.errorLogin = true;
    });
  },

  /*   async onSubmit() {
    const data = {
     email: this.form.email,
     password: this.form.password,
    };
    axios
     .post(`${this.$cookie.getCookie("API")}/api/login`, data)
     .then((res) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
      localStorage.setItem("token", res.data.token);
      this.$store.dispatch("token", res.data.token);
      if (
       res.data.user.accounts.includes("ADMIN") ||
       res.data.user.activeAccount.includes("ADMIN")
      ) {
      if (
       res.data.user.accounts.includes("ADMIN") ||
       res.data.user.activeAccount.includes("ADMIN")
      ) {
       localStorage.setItem("adminToken", res.data.token);
       this.$store.dispatch("adminToken", res.data.token);
      }
      localStorage.setItem("avatar", res.data.user.avatar);
      if (!res.data.user.activeAccount) {
       localStorage.setItem("account", res.data.user.accounts[0]);
       this.$store.dispatch("account", res.data.user.accounts[0]);
       this.account = res.data.user.accounts[0];
      } else {
       localStorage.setItem("account", res.data.user.activeAccount);
       this.$store.dispatch("account", res.data.user.activeAccount);
       this.account = res.data.user.activeAccount;

       localStorage.setItem("customerAccounts", JSON.stringify(res.data.customerAccounts));

       const activeAccount = res.data.customerAccounts.filter(
        (account) => account.customerAccount === res.data.user.activeAccount
       );
       if (activeAccount.length > 0) {
        localStorage.setItem(
         "activeAccount",
         activeAccount[0].name + " - " + activeAccount[0].customerAccount
        );
       }
      }

      localStorage.setItem("showBanner", true);
      this.$store.dispatch("user", res.data.user);
      localStorage.setItem("user", JSON.stringify(res.data.user));

      const initials = res.data.user.name
       .split(" ")
       .map((name) => name.charAt(0))
       .join("");
      localStorage.setItem("initials", initials);
      this.$store.dispatch("userAvatar", res.data.user.avatar);
      if (this.account !== "ADMIN") {
       this.getPbxHosts(
        res.data.token,
        res.data.user.activeAccount ? res.data.user.activeAccount : res.data.user.accounts[0]
       );
      }
      this.$forceUpdate();
     })
     .then((res) => {
      this.errorLogin = false;
      if (this.account === "ADMIN") {
       this.$router.push("/impersonate");
      } else {
       this.$router.push("/");
      }
      //this.apiLogin();
     })
     .catch((err) => {
      console.log(err);
      localStorage.removeItem("token");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("avatar");
      localStorage.removeItem("account");
      this.errorLogin = true;
      //window.alert("Connection refused. Please try again later.");
     });
   }, */
  async apiLoginSandbox() {
   axios
    .get(`https://extranet.encom.tel/api/v1/expert-stats/token`, {
     headers: {
      Authorization:
       "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiNjBjMzdmZDdhMmQ4NzE0NTQ1NTk5N2E4NDcwOTVmM2ViZDJkOGMxNmYxYzBhOGUxZjlmMWEzNGJhZDg3ZDRjODg4N2U2ZjYwYjFmNWI2MjgiLCJpYXQiOjE3MDY2MDk5NzguNDA4MDUwMSwibmJmIjoxNzA2NjA5OTc4LjQwODA1MjksImV4cCI6MTczODIzMjM3OC40MDY2MTYsInN1YiI6IjIzNzIiLCJzY29wZXMiOltdfQ.fb754PQrnFib5Bcz1Xpp16cOUcrLSTu61af7lB_LcenRNpXcxe8EB4SxE7RL47z-KLfpo7dvn3ohSKkS8kvy4VOOXtdLyHEh3k-4YXjZOqbZEmr0goeOZdpK2_koCHB4O9NXKSBggEHwWvipwE_Fd7JUCqjwBrGwD98EncphS8zDBfjRA255iBXchUIYTgx79W6Rfterl_Tg4K3-fzaOgkT-JjJ_dp6KqgWPTGBT6gehZAnqCYQmo6r74DiSalWOcU7_sYOTEWL_ehttrL5RVDYLi81QxXJgK6x34iIjwq1AuxYJZIuRCf9sYgzPK-LmkzVYM9VK7G-gqifv4VXw3dKEAroJ88cG2UW0eSUmeybFZYy5h5hcHCQVWQgw71O3jAXRE9Y25LT4-gUGnTXBql0NhWx-feLbRtpsUyDpCBqcg9mY-n6_8d67wFo9FMGtclNsODUXANsZWiDk9DYdDoRbHGcxtqH04ogB99Gxk7OzVOGt1_LD9jSPZXziKYo3UtjTD-Q2-V7NJyqmK1CezwZXBkYqgb-6JfNDDQu_207GmUJ_XJgcaSjaahPrG9Nxt8YtrLibTfGXOkj3g-tIwKKSJOguF5iYbCjWskidZcLN21y4ZL6cO8WLkJR-vUYwMQsog0wPoy1baUNAebkNPUMeh5MUEE3T_PPLwdB2te4",
     },
    })
    .then(res => {
     localStorage.setItem("apiToken", res.data.token);
     localStorage.setItem("apiUrl", res.data.url);
     this.$forceUpdate();
    })
    .catch(err => {
     //  this.errorHandling(err);
    });
  },
  async apiLogin() {
   axios
    .get(`https://extranet.encom.tel/api/v1/expert-stats/token`)
    .then(res => {
     localStorage.setItem("apiToken", res.data.token);
     localStorage.setItem("apiUrl", res.data.url);
     this.$forceUpdate();
    })
    .catch(err => {
     console.log("file: LoginCard.vue:270 ~ apiLogin ~ err:", err);
     //  this.errorHandling(err);
    });
  },
  async getCustomerCode(account) {
   try {
    const res = await axios.get(
     `${localStorage.getItem(
      "apiUrl"
     )}/api/v1.2/customer-from-origin/0000/${account}`,
     {
      headers: {
       Authorization: "Bearer " + token,
      },
     }
    );
    return res.data.code;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getPbxHosts(token, account) {
   if (this.userPermissions && this.userPermissions.permissions) {
    if (
     (this.userPermissions.permissions?.includes("pbx-hosts.*") ||
      this.userPermissions.permissions?.includes("pbx-hosts.view")) &&
     this.token &&
     this.account !== "ADMIN"
    ) {
     const code = await this.getCustomerCode(token, account);
     try {
      const res = await axios.get(
       `${localStorage.getItem(
        "apiUrl"
       )}/api/v1.2/customer-pbx3cx-host?customer_code=${code}`,
       {
        headers: {
         Authorization: "Bearer " + token,
        },
       }
      );

      if (res.data && res.data.length > 0) {
       this.$store.dispatch(
        "hostNames",
        res.data.map(res => res.host_name)
       );
       let dataHostName = res.data.map(res => res.host_name)
        ? res.data.map(res => res.host_name)[0]
        : null;
       if (
        !this.hostName ||
        this.hostName === "null" ||
        this.hostName !== dataHostName
       ) {
        this.$store.dispatch("hostName", dataHostName);
        localStorage.setItem("hostName", dataHostName);
       }
      }
     } catch (error) {
      this.errorHandling(error);
     }
     //  try {
     //   const res = await axios.get(
     //    `${this.$cookie.getCookie("API")}/api/v1/pbxHosts`,
     //    {
     //     params: { customerAccount: this.account },
     //     headers: {
     //      Authorization: `Bearer ${this.token}`,
     //     },
     //    }
     //   );
     //   if (res.data && res.data.length > 0) {
     //    this.$store.dispatch("hostNames", res.data);
     //    if (
     //     !this.hostName ||
     //     this.hostName === "null" ||
     //     this.hostName !== res.data[0]
     //    ) {
     //     this.$store.dispatch("hostName", res.data[0]);
     //     localStorage.setItem("hostName", res.data[0]);
     //    }
     //   }
     //   this.hostsNames = res.data;
     //  } catch (error) {
     //   this.errorHandling(error);
     //  }
    }
   }
  },
  emitQueryParam(fieldValue, email) {
   if (fieldValue === "newPassword") {
    const key = "first_login";
    const value = "1";

    const queryParams = {
     [key]: value,
    };

    // Emit an event with the query parameters
    this.$emit("resetPassword", email, queryParams);
   } else {
    const key = "reset_password";
    const value = "1";

    const queryParams = {
     [key]: value,
    };

    // Emit an event with the query parameters
    this.$emit("resetPassword", email, queryParams);
   }
  },
  async getModules() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/modules`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    const data = JSON.stringify(res.data);
    localStorage.setItem("modules", data);
    this.$store.commit("setModules", res.data);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async getServices(account) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/customer-services?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    const data = JSON.stringify(res.data);
    localStorage.setItem("services", data);
    this.$store.commit("setServices", res.data);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async getMyPermissions(account) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/auth/my-permissions?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    this.$store.commit("setUserPermissions", res.data ? res.data : null);
    localStorage.setItem("userPermissions", JSON.stringify(res.data));
    // const data = JSON.stringify(res.data);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
 },
 mounted() {
  if (this.isGuestUser) {
   this.isDisabled = true;
   this.form.email = "login@cx-engine.net";
   this.form.password = "secret";
  }
 },
 watch: {
  isGuestUser: function (val) {
   if (val) {
    this.isDisabled = true;
    this.form.email = "login@cx-engine.net";
    this.form.password = "secret";
   }
  },
 },
 computed: {
  ...mapGetters(["logo", "token", "hostName"]),
 },
};
</script>

<style></style>
